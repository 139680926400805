import React from 'react';
import './InfoWindow.scss';
import Icon from '../../icons';

export const VehicleInfoWindow = ({ item }) => {
  const headerContent = (
    <div className="info-header">
      <Icon type="InfoWindowCar" className="info-icon" color={item.color} />
      <span className="info-id" style={{ color: item.color }}>
        {item?.driver?.name}
      </span>
    </div>
  );

  const infoWindow = (
    <div className="info-window">
      <div className="info-content">
        <div className="info-detail">
          <strong>Start Address</strong> <span>{item.startAddress}</span>
        </div>
        <div className="info-detail">
          <strong>End Location</strong> <span>-</span>
        </div>
        <div className="info-detail">
          <strong>Start Time</strong> <span>{item.shiftStart}</span>
        </div>
        <div className="info-detail">
          <strong>End Time</strong> <span>{item.shiftEnd}</span>
        </div>
      </div>
    </div>
  );
  return { headerContent, infoWindow };
};

export const StopInfoWindow = ({ item }) => {
  const headerContent = (
    <div className="info-header">
      <Icon type="InfoWindowCar" className="info-icon" />
      <span className="info-id" style={{ color: item.color }}>
        {item.orderCode}
      </span>
    </div>
  );

  const infoWindow = (
    <div className="info-window">
      <div className="info-content">
        <div className="info-detail">
          <strong>Location</strong> <span>{item?.address}</span>
        </div>
        <div className="info-detail">
          <strong>Duration</strong> <span>{item?.duration}</span>
        </div>
        <div className="info-detail">
          <strong>Delivery Name</strong> <span>{item?.deliveryName}</span>
        </div>
        <div className="info-detail">
          <strong>Arrival Time</strong> <span>{item?.arrivalTime || '-'}</span>
        </div>
        <div className="info-detail">
          <strong>Finish Time</strong> <span>{item?.finishTime || '-'}</span>
        </div>
        <div className="info-detail">
          <strong>Start Time</strong> <span>{item?.startTime}</span>
        </div>
        <div className="info-detail">
          <strong>End Time</strong> <span>{item?.endTime}</span>
        </div>
        <div className="info-detail">
          <strong>Type</strong> <span>{item?.type}</span>
        </div>
      </div>
    </div>
  );
  return { headerContent, infoWindow };
};
