import React from 'react';
import forEach from 'lodash/forEach';

import GoogleMap from '../../components/Map/GoogleMap';
import Icon from '../../icons';
import { stringToColorHex } from '../../utils/common';
import { STEP_TYPE } from '../../constants';

const RouteMap = ({ mapData, vehicles = [], routes = [], onClickStep, active }) => {
  const newMarkers = [];
  const newPolylines = [];
  if (mapData) {
    forEach(routes, (route) => {
      const mapRoute = mapData[route.driver.id];
      if (!mapRoute) return;

      const steps = mapRoute.steps;
      const polyline = mapRoute.polyline;
      const color = mapRoute.color;
      forEach(steps, (step) => {
        newMarkers.push({
          ...step,
          content: (
            <Icon
              type={step.type === STEP_TYPE.pickup ? 'MarkerPickup' : 'MarkerDropoff'}
              text={step.orderNumber}
              color={color}
              textColor={color}
            />
          ),
          latitude: Number(step.lat),
          longitude: Number(step.lng),
          offsetX: 1,
          offsetY: -6,
          duration: STEP_TYPE.pickup === step.type ? step.pickupDuration : step.deliveryDuration,
          onClick: () => onClickStep(step),
        });
      });
      if (polyline) {
        newPolylines.push({
          polyline: polyline,
          color: color,
          strokeWeight: 2,
          strokeOpacity: 0.8,
        });
      }
    });
    if (mapData['unscheduled']?.steps.length > 0) {
      forEach(mapData['unscheduled']?.steps, (stop, index) => {
        const stopOrder = index + 1;
        const markerColor = '606060';
        newMarkers.push({
          ...stop,
          content: (
            <Icon
              type={stop.type === STEP_TYPE.pickup ? 'MarkerPickup' : 'MarkerDropoff'}
              text={stopOrder}
              color={`#${markerColor}`}
            />
          ),
          latitude: Number(stop.lat),
          longitude: Number(stop.lng),
          offsetX: 1,
          offsetY: -6,
          onClick: () => onClickStep(stop),
        });
      });
    }
  }

  forEach(vehicles, (vehicle) => {
    const color = stringToColorHex(vehicle.driver?.name + `${vehicle.driver.id}`);
    newMarkers.push({
      ...vehicle,
      id: `${vehicle.id}-start`,
      content: <Icon type="MarkerStart" color={color} />,
      latitude: Number(vehicle.startLat),
      longitude: Number(vehicle.startLng),
      offsetX: 1,
      offsetY: -6,
      type: 'start',
      onClick: () => onClickStep({ id: `${vehicle.id}-start`, type: 'vehicle' }),
    });
  });
  return (
    <GoogleMap markers={newMarkers} className="mapbox-routePlan-detail" polylines={newPolylines} active={active} />
  );
};

export default RouteMap;
