/* eslint-disable no-unused-vars */
import React from 'react';
import { getWidthChart, convertMinutesToPixel, convertToInt } from '../../utils/common';
import { COLOR_DEFAULT, STEP_TYPE } from '../../constants';
import { Popover } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Draggable } from 'react-beautiful-dnd';
import StepContent from './StepContent';
import map from 'lodash/map';
import Icon from '../../icons';

const DriverListSteps = ({
  route,
  startTime,
  oneHourPx,
  color,
  solutions,
  stepCompleted,
  steps,
  minutes,
  stepsHovered,
  setStepsHovered,
  onHoverStep,
  handleCloseStepPopup,
  onClickStep,
  onClickRoute,
  setShowMoreRoute,
  routePlan,
  selectedMultipleSteps,
  multipleStepState,
  driverId,
  selectedStep,
}) => {
  const shiftStart = route.shiftStart;
  const shiftEnd = route.shiftEnd;

  const widthStart = getWidthChart(startTime, shiftStart, oneHourPx);
  const widthEnd = getWidthChart(startTime, shiftEnd, oneHourPx);
  const widthRoad = getWidthChart(shiftStart, shiftEnd, oneHourPx);
  const defaultColor = COLOR_DEFAULT;
  const stepsDone = stepCompleted ? stepCompleted[route.driver.id] : [];

  return (
    <div className="driver-row">
      <Popover
        open={false}
        content={
          <div>
            {route.startAddress}
            <div className="actions">
              <span style={{ margin: 0 }}>
                Show more <ArrowRightOutlined />
              </span>
            </div>
          </div>
        }
      >
        <div
          className="truck-start"
          onClick={() =>
            onClickStep &&
            onClickStep({
              id: `${route.id}-start`,
              type: 'vehicle',
            })
          }
          style={{ left: widthStart - 17 }}
        >
          <Icon type={'RouteDetailsStart'} color={'#' + (color || defaultColor)} />
        </div>
      </Popover>
      <div
        className="line"
        style={{
          width: widthRoad,
          left: widthStart,
          background: `#${color || defaultColor}`,
        }}
      ></div>
      <Popover
        open={false}
        content={
          <div>
            {route.endAddress}
            <div className="actions">
              <span
                // onClick={() => setShowMoreRoute(true)}
                style={{ margin: 0 }}
              >
                Show more <ArrowRightOutlined />
              </span>
            </div>
          </div>
        }
      >
        <div className="truck-end" onClick={() => onClickRoute(route, 'end')} style={{ left: widthEnd - 11 }}>
          <Icon type={'RouteDetailsEnd'} color={'#' + (color || defaultColor)} />
        </div>
      </Popover>
      {steps.length > 0 && (
        <div className="steps">
          {map(steps, (step, indexStep) => {
            const keyDraggable = `draggable${step.id}`;
            const stepPopover = `StepPopover-${step.id}-${step.type.toLowerCase()}`;
            const width = step.width;
            const widthDuration = convertMinutesToPixel(step.duration, oneHourPx);
            const endShift = convertToInt(shiftEnd);
            const endTime = convertToInt(step.endTime);
            const finishTime = convertToInt(step.finishTime);
            const arrivalTimeSecs = convertToInt(step.arrivalTime);

            const isLate =
              (finishTime >= 0 && endTime >= 0 && finishTime > endTime) ||
              (finishTime >= 0 && endShift >= 0 && finishTime > endShift) ||
              (stop.endTime && arrivalTimeSecs > endTime);
            const stepSolution = solutions[route.driver.id]?.find((rs) => rs.stepId === step.stepId) || {};

            let isCompleted = step.status === 'COMPLETED' || step.orderStatus === 'COMPLETED';

            if (step.type === STEP_TYPE.pickup) {
              const orderIds = step.orderIds || [];
              const routeSteps = route.steps || [];
              routeSteps.forEach((routeStep) => {
                if (isCompleted || routeStep.type === STEP_TYPE.delivery) return;
                if (routeStep.status !== 'IN_PROGRESS' && orderIds.includes(routeStep.orderId)) isCompleted = true;
              });
            }

            return (
              <Draggable key={keyDraggable} draggableId={keyDraggable} index={indexStep} isDragDisabled={isCompleted}>
                {(provided) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        width: width,
                        minWidth: 12,
                        // zIndex: stepsHovered[stepPopover] ? 99999 : 10,
                        marginLeft: stepSolution.marginLeft + (indexStep === 0 ? widthStart : 0),
                        ...provided.draggableProps.style,
                      }}
                    >
                      <StepContent
                        step={step}
                        routePlan={routePlan}
                        route={route}
                        indexStep={indexStep + (stepsDone?.length || 0)}
                        color={'#' + color}
                        stepPopover={stepPopover}
                        widthDuration={widthDuration}
                        handleCloseStepPopup={handleCloseStepPopup}
                        onClickStep={onClickStep}
                        selectedMultipleSteps={selectedMultipleSteps}
                        multipleStepState={multipleStepState}
                        driverId={driverId}
                        selectedStep={selectedStep}
                      />
                    </div>
                  );
                }}
              </Draggable>
            );
          })}
        </div>
      )}
      <div className="stepsDone">
        {map(stepsDone, (step, indexStep) => {
          // const indexStep = steps.length + indexStepDone - 1;
          const keyDraggable = `draggable${step.id}`;
          const stepPopover = `StepPopover-${step.id}-${step.type.toLowerCase()}`;
          const width = step.width;
          const widthDuration = convertMinutesToPixel(step.duration, oneHourPx);
          const endShift = convertToInt(shiftEnd);
          const endTime = convertToInt(step.endTime);
          const finishTime = convertToInt(step.finishTime);
          const arrivalTimeSecs = convertToInt(step.arrivalTime);

          const isLate =
            (finishTime >= 0 && endTime >= 0 && finishTime > endTime) ||
            (finishTime >= 0 && endShift >= 0 && finishTime > endShift) ||
            (stop.endTime && arrivalTimeSecs > endTime);

          let isCompleted = true;

          if (step.type === STEP_TYPE.pickup) {
            const orderIds = step.orderIds || [];
            const routeSteps = route.steps || [];
            routeSteps.forEach((routeStep) => {
              if (isCompleted || routeStep.type === STEP_TYPE.delivery) return;
              if (routeStep.status !== 'IN_PROGRESS' && orderIds.includes(routeStep.orderId)) isCompleted = true;
            });
          }

          return (
            <Draggable key={keyDraggable} draggableId={keyDraggable} index={indexStep} isDragDisabled={true}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      width: width,
                      minWidth: 12,
                      // zIndex: stepsHovered[stepPopover] ? 99999 : 10,
                      marginLeft: step.marginLeft + (indexStep === 0 ? widthStart : 0),
                      ...provided.draggableProps.style,
                    }}
                  >
                    <StepContent
                      step={step}
                      routePlan={routePlan}
                      route={route}
                      indexStep={indexStep}
                      color={'#' + color}
                      stepPopover={stepPopover}
                      widthDuration={widthDuration}
                      onHoverStep={onHoverStep}
                      handleCloseStepPopup={handleCloseStepPopup}
                      onClickStep={onClickStep}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        })}
      </div>
      <div className="all-minutes">
        {minutes.map((minute, index) => {
          return <div className="minutes" key={index} style={{ width: `${oneHourPx / 2}px` }}></div>;
        })}
      </div>
    </div>
  );
};

export default DriverListSteps;
