export const APP_NAME = 'sparqle';
export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME || '_ovo_session';

export const TIME_FORMAT = 'HH:mm';
export const DAYTIME_FORMAT = 'ddd, MMM Do, YYYY - HH:mm';
export const DAY_FORMAT = 'ddd, MMM Do, YYYY';
export const FULL_DATE_FORMAT = 'ddd, MMM Do, YYYY';
export const DATE_BACKEND_FORMAT = 'YYYY-MM-DD';

export const PAGE_SIZE = 15;

export const PAGE_SIZE_OPTIONS = [PAGE_SIZE, 50, 100];

export const HUB_TAG = {
  CITY_HUB: 'City Hub',
  MICRO_HUB: 'Micro Hub',
  NANO_HUB: 'Nano Hub',
  BIKE_PARK: 'Bike Park',
};

export const FLEET_TAG = {
  BIKE: 'Bike',
  // MODULE: "Module",
  // TRAILER: "Trailer",
  VAN: 'Van',
};

export const FLEET_STATUSES = {
  FUNCTIONAL: 'Functional',
  UNFUNCTIONAL: 'Unfunctional',
  MAINTENANCE: 'Maintenance',
};

export const ORDER_STATUS = {
  ERROR: 'Error',
  DRAFT: 'Draft',
  GEOCODING: 'Geocoding',
  UNASSIGNED: 'Unassigned',
  ASSIGNED: 'Assigned',
  UNSCHEDULED: 'Unscheduled',
  GOING_NEXT: 'Going Next',
  SKIPPED: 'Skipped',
  PICKUP_ARRIVED: 'Pickup Arrived',
  PICKUP_FAILED: 'Pickup Failed',
  COLLECTED: 'Collected',
  IN_TRANSIT: 'In Transit',
  DELIVERY_ARRIVED: 'Delivery Arrived',
  DELIVERY_FAILED: 'Delivery Failed',
  COMPLETED: 'Completed',
};

export const STEP_TYPE = {
  pickup: 'PICKUP',
  delivery: 'DELIVERY',
};

export const COLOR_DEFAULT = '7732EF';
