import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  doChangeToDraft,
  doChangeToUnassigned,
  doCreateOrder,
  doGetOrder,
  doGetOrders,
  doUpdateOrder,
  doUploadOrders,
} from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('Orders/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('Orders/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('Orders/') && action.type.endsWith('/rejected');
};

const ordersAdapter = createEntityAdapter({
  selectId: (item) => item.orderCode,
});

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    action: null,
    isFetching: false,
    currentOrder: null,
    status: 'idle',
    metadata: {},
    entities: [],
    ids: [],
    item: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetOrders.pending, (state) => {
      state.action = 'getall';
      state.status = 'loading';
    });
    builder.addCase(doGetOrders.fulfilled, (state, action) => {
      const { orders, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      ordersAdapter.setAll(state, orders || []);
    });
    builder.addCase(doGetOrders.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetOrder.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetOrder.fulfilled, (state, action) => {
      state.status = 'succeeded';
      if (action.payload) {
        state.currentOrder = action.payload;
      }
    });
    builder.addCase(doGetOrder.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCreateOrder.pending, (state) => {
      state.action = 'create';
      state.status = 'loading';
    });
    builder.addCase(doCreateOrder.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doChangeToUnassigned.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doChangeToUnassigned.pending, (state) => {
      state.action = 'changeToUnassigned';
      state.status = 'loading';
    });
    builder.addCase(doChangeToUnassigned.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doChangeToDraft.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doChangeToDraft.pending, (state) => {
      state.action = 'changeToDraft';
      state.status = 'loading';
    });
    builder.addCase(doChangeToDraft.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCreateOrder.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doUpdateOrder.pending, (state) => {
      state.action = 'update';
      state.status = 'loading';
    });
    builder.addCase(doUpdateOrder.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doUpdateOrder.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doUploadOrders.pending, (state) => {
      state.action = 'upload';
      state.status = 'loading';
    });
    builder.addCase(doUploadOrders.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doUploadOrders.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllOrders,
  selectById: selectOrderById,
  selectIds: selectOrdersIds,
} = ordersAdapter.getSelectors((state) => state.order);
const { reducer: OrderReducer } = orderSlice;
export default OrderReducer;
